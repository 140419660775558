<template>
  <!-- <div id="nav">
    <router-link to="/">Inicio</router-link> |
    <router-link to="/about">Acerca de</router-link>
  </div> -->
  <router-view />
</template>

<style>
:root {
  --primary-blue-color: #3983cc;
  --secundary-blue-color: #09122f;
  --light-blue-color: #b0cdeb;
  --primary-red-color: #aa221c;
  --secondary-blue-color: #3286cf;
  --text-color: #1d1d1b;
  --text-dark-color: #2c2042;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@font-face {
  font-family: "RobotoSlab-Regular";
  src: url("assets/fonts/Roboto/RobotoSlab-Regular.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "RobotoSlab-Bold";
  src: url("assets/fonts/Roboto/RobotoSlab-Bold.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("assets/fonts/Roboto/Roboto-Medium.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("assets/fonts/Roboto/Roboto-BlackItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("assets/fonts/Roboto/Roboto-Thin.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("assets/fonts/Roboto/Roboto-Light.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "HelveticaWorld-Regular";
  src: url("assets/fonts/HelveticaWorld/HelveticaWorld-Regular.ttf")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "HelveticaWorld-Italic";
  src: url("assets/fonts/HelveticaWorld/HelveticaWorld-Italic.ttf")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "HelveticaWorld-Bold";
  src: url("assets/fonts/HelveticaWorld/HelveticaWorld-Bold.ttf")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "HelveticaWorld-BoldItalic";
  src: url("assets/fonts/HelveticaWorld/HelveticaWorld-BoldItalic.ttf")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "RubikDistressed-Regular";
  src: url("assets/fonts/RubikDistressed/RubikDistressed-Regular.ttf")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/Nexa/Nexa-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}

.roboto-light {
  font-family: "Roboto Light", serif;
  color: #000;
}

.roboto-thin {
  font-family: "Roboto Thin", serif;
}

.roboto {
  font-family: Roboto, serif;
}

.roboto-medium {
  font-family: Roboto-Medium, sans-serif;
}

.roboto-mediumitalic {
  font-family: Roboto-MediumItalic, sans-serif;
}

.roboto-blackitalic {
  font-family: Roboto-BlackItalic, sans-serif;
}

.roboto-bold {
  font-family: Roboto-Bold, sans-serif;
  color: #051231;
}

.roboto-slab {
  font-family: RobotoSlab-Regular, serif;
}

.roboto-slab-bold {
  font-family: RobotoSlab-Bold, serif;
}

.helvetica {
  font-family: HelveticaWorld-Regular, serif;
}

.helvetica-bold {
  font-family: HelveticaWorld-Bold, serif;
}

.helvetica-italic {
  font-family: HelveticaWorld-Italic, serif;
}

.helvetica-bolditalic {
  font-family: HelveticaWorld-BoldItalic, serif;
}

.rubik-distressed {
  font-family: RubikDistressed-Regular, serif;
}

.regular-text-color {
  color: var(--text-color);
}

.dark-color {
  color: var(--text-dark-color);
}
</style>
