<template>
  <div class="home">
    <div class="control-panel" style="width: 95%; margin: 30px auto 0 auto">
      <div>
        <label>Bins:</label>
        <input min="1" type="number" v-model="bins" placeholder="Bins" />
      </div>
      <div>
        <label>Fecha:</label> <input type="date" v-model="selectedDate" />
      </div>
      <div>
        <label>Moneda:</label>
        <select v-model="selectedCurrency">
          <option value="USD">USD</option>
          <option value="USDT_TRC20">USDT</option>
          <option value="ECU">EUR</option>
          <option value="MLC">MLC</option>
        </select>
      </div>
      <div>
        <input type="checkbox" v-model="showPrev" id="semanaant" />
        <label for="semanaant"> Ver semana anterior</label>
      </div>
    </div>
    <button @click="copyToClipboard">Copiar al portapapeles</button>
    <button @click="saveChartsAsImage">Guardar gráficos como imagen</button>

    <div class="dim-container">
      <chart-container :is-loading="isLoading">
        <v-chart
          v-if="option && !isLoading"
          class="chart"
          ref="chart1"
          :option="option"
        >
        </v-chart>
      </chart-container>
    </div>

    <div class="chart-top-space" />

    <div class="dim-container">
      <chart-container :is-loading="isLoading" v-if="showPrev">
        <v-chart
          v-if="option && !isLoading"
          class="chart"
          ref="chart2"
          :option="optionOLD"
        >
        </v-chart>
      </chart-container>
    </div>

    <div class="chart-top-space" />

    <div class="dim-container">
      <div ref="statisticsTable" class="table-container">
        <div>
          <table class="statistics-table">
            <thead>
              <tr>
                <th></th>
                <th>{{ formattedDate }}</th>
                <th v-if="showPrev">{{ formattedPreviousWeekDate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>mediana</td>
                <td>{{ median }}</td>
                <td v-if="showPrev">{{ prevMedian }}</td>
              </tr>
              <tr>
                <td>promedio</td>
                <td>{{ mean.toFixed(4) }}</td>
                <td v-if="showPrev">{{ prevMean.toFixed(4) }}</td>
              </tr>
              <tr>
                <td>moda</td>
                <td>{{ mode }}</td>
                <td v-if="showPrev">{{ prevMode }}</td>
              </tr>
              <tr>
                <td>desviación estándar</td>
                <td>{{ std.toFixed(4) }}</td>
                <td v-if="showPrev">{{ prevStd.toFixed(4) }}</td>
              </tr>
              <tr>
                <td>asimetría</td>
                <td>{{ basicSkewness.toFixed(4) }}</td>
                <td v-if="showPrev">{{ prevBasicSkewness.toFixed(4) }}</td>
              </tr>
              <tr>
                <td>curtosis</td>
                <td>{{ kurtosis.toFixed(4) }}</td>
                <td v-if="showPrev">{{ prevKurtosis.toFixed(4) }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td v-if="showPrev"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="chart-top-space" />

    <div class="dim-container">
      <pre class="font-nexa text-left whitespace-pre-wrap">
        {{ replacedText }}
      </pre>
    </div>
    <img
      id="img_histograma_actual"
      ref="chartImageActual"
      alt="Charts Image"
      style="display: none; margin-top: 20px"
    />
    <img
      id="img_histograma_previo"
      ref="chartImagePrev"
      alt="Charts Image 2"
      style="display: none; margin-top: 20px"
    />
    <img
      id="img_histograma_tabla"
      ref="tableImage"
      alt="Charts Image"
      style="display: none; margin-top: 20px"
    />
  </div>
</template>

<script>
import ChartContainer from "../components/ChartContainer";
import * as ss from "simple-statistics";
import ShapiroWilk from "../utils/shapirowilk";
import domtoimage from "dom-to-image-font-patch";
import FileSaver from "file-saver";

const AXIS_FONT_SIZE = 14;
const MINIMUM_X_AXIS_INTERVAL = 5;
const MEAN_MODE_LABEL_FONT_SIZE = 14;
const CURRENCY_LIMITS = {
  USD: [21, 450],
  USDT_TRC20: [21, 450],
  ECU: [24, 450],
  MLC: [21, 450],
};

const CURRENCY_NAMES = {
  USD: "USD",
  USDT_TRC20: "USDT",
  ECU: "EURO",
  MLC: "MLC",
};

const COLORS = {
  HISTOGRAM_BAR: "#5a9de6",
  HISTOGRAM_PREV_BAR: "#b0cdeb",
  X_AXIS_SPLIT_LINE_COLOR: "#5a9de6",
  Y_AXIS_SPLIT_LINE_COLOR: "#cc6370",
  X_AXIS_LINE_COLOR: "#cc6370",
  Y_AXIS_LINE_COLOR: "#5a9de6",
  MEDIAN_LINE_COLOR: "#1149c8",
  MEAN_LINE_COLOR: "#e13449",
  AXIS_LABEL_COLOR: "#cc6370",
  AXIS_NAME_COLOR: "black",
  BACKGROUND_COLOR: "#ecf3f8",
};

export default {
  name: "HistogramChart",
  components: { ChartContainer },
  props: {
    title: String,
    currencies: Array,
    initialSelectedCur: String,
  },
  computed: {
    minMaxLimits() {
      return CURRENCY_LIMITS[this.selectedCurrency] || [0, Infinity];
    },
    minLimit() {
      return this.minMaxLimits[0];
    },
    maxLimit() {
      return this.minMaxLimits[1];
    },
    currencyName() {
      return CURRENCY_NAMES[this.selectedCurrency];
    },
    formattedDate() {
      return this.formatDate(this.selectedDate);
    },
    formattedPreviousWeekDate() {
      return this.formatDate(this.previousWeekDate);
    },
    replacedText() {
      return this.textContent.replace("$date", this.formattedDate);
    },
    previousWeekDate() {
      // Calls the method to compute the previous week date
      return this.getPreviousWeekDate(this.selectedDate);
    },
  },
  watch: {
    bins: {
      handler: "getOptions",
    },
    selectedDate: {
      handler: "getOptions",
    },
    selectedCurrency: {
      handler: "getOptions",
    },
    seriesData: {
      handler: "evaluateNormality",
    },
  },
  data() {
    const yesterday = new Date();
    // Subtract one day
    yesterday.setDate(yesterday.getDate() - 1);
    // Format as YYYY-MM-DD
    const formattedYesterday = yesterday.toISOString().split("T")[0];

    return {
      isLoading: false,
      textContent: "",
      selectedDate: formattedYesterday,
      bins: 5,
      showPrev: true,
      selectedCurrency: "USD",
      offerType: "General",
      bottom: 0,
      top: 0,
      prevBottom: 0,
      prevTop: 0,
      lowerBound: 0,
      upperBound: 0,
      prevLowerBound: 0,
      prevUpperBound: 0,
      esnormal: "Unknown",
      prevEsnormal: "Unknown",
      option: null,
      optionOLD: null,
      shapiroWilkPValue: 0,
      shapiroWilkStats: 0,
      prevShapiroWilkPValue: 0,
      prevShapiroWilkStats: 0,
      skewness: 0,
      basicSkewness: 0,
      prevSkewness: 0,
      prevBasicSkewness: 0,
      kurtosis: 0,
      prevKurtosis: 0,
      median: 0,
      prevMedian: 0,
      mode: 0,
      prevMode: 0,
      mean: 0,
      prevMean: 0,
      std: 0,
      prevStd: 0,
      seriesData: [],
      prevSeriesData: [],
      expandendData: [],
      prevExpandendData: [],
      data: [],
      prevData: [],
    };
  },
  mounted() {
    this.getOptions();
    this.setCaptionText();
  },
  methods: {
    formatDate(dd_mm_yyyy) {
      const parts = dd_mm_yyyy.split("-");
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    },
    setCaptionText() {
      fetch("/histogram_message.txt")
        .then((response) => response.text())
        .then((text) => {
          this.textContent = text;
        })
        .catch((error) => {
          console.error("Error al cargar el archivo:", error);
        });
    },
    async captureElementAsImage(sourceElement, targetElement, param, filename) {
      if (!sourceElement) {
        console.error("No se ha proporcionado un elemento fuente.");
        return;
      }

      try {
        const scale = 2;

        const blob = await domtoimage.toBlob(sourceElement, {
          quality: 1,
          width: sourceElement.clientWidth * scale,
          height: sourceElement.clientHeight * scale,
          style: {
            transform: `scale(${scale})`,
            transformOrigin: "top left",
          },
        });

        if (param !== "update") {
          FileSaver.saveAs(blob, filename);
        }

        const imageUrl = URL.createObjectURL(blob);

        if (targetElement) {
          targetElement.src = imageUrl;
          targetElement.style.display = "block";
        } else {
          console.error(
            "No se ha proporcionado un elemento destino para mostrar la imagen."
          );
        }
      } catch (error) {
        console.error("Error al capturar el elemento:", error);
      }
    },
    async saveChartsAsImage(param) {
      const chart1 = this.$refs.chart1 ? this.$refs.chart1.$el : null;
      const chart2 =
        this.showPrev && this.$refs.chart2 ? this.$refs.chart2.$el : null;
      const statisticsTable = this.$refs.statisticsTable
        ? this.$refs.statisticsTable
        : null;

      this.captureElementAsImage(
        chart1,
        this.$refs.chartImageActual,
        param,
        "histograma.png"
      );
      this.captureElementAsImage(
        chart2,
        this.$refs.chartImagePrev,
        param,
        "histograma_prev.png"
      );
      this.captureElementAsImage(
        statisticsTable,
        this.$refs.tableImage,
        param,
        "statisticsTable.png"
      );
    },
    exportHistogramData(series) {
      let csvContent = ""; // Encabezado del CSV
      series.forEach((serie) => {
        if (serie.name && serie.name.includes("Histograma")) {
          csvContent += `${serie.name}\n`;

          // Añadir los encabezados de las columnas
          csvContent += "xData,yData\n";

          // Añadir cada punto de datos como una fila en la tabla
          serie.data.forEach((point) => {
            if (point[0] && point[1]) csvContent += `${point[0]},${point[1]}\n`;
          });

          // Añadir una línea en blanco para separar las tablas
          csvContent += "\n";
        }
      });

      return csvContent;
    },
    getPreviousWeekDate(date) {
      const d = new Date(date);
      d.setDate(d.getDate() - 7);
      return d.toISOString().split("T")[0];
    },
    evaluateNormality() {
      const evaluate = (data) => {
        if (!data || data.length < 1) return { isNormal: "Unknown", pValue: 0 };
        const mean = ss.mean(data);
        const standardDeviation = ss.standardDeviation(data);

        const normalizedData = data.map(
          (value) => (value - mean) / standardDeviation
        );
        const shapiroWilk = ShapiroWilk(normalizedData);
        const isNormal = shapiroWilk.pValue > 0.05;
        return {
          isNormal: isNormal ? "Normal" : "Not normal",
          estadistico: shapiroWilk.W,
          pValue: shapiroWilk.pValue < 0.0001 ? 0 : shapiroWilk.pValue,
        };
      };
      const currentEval = evaluate(this.expandendData);
      const prevEval = evaluate(this.prevExpandendData);
      this.esnormal = currentEval.isNormal;
      this.shapiroWilkPValue = currentEval.pValue;
      this.shapiroWilkStats = currentEval.estadistico;
      this.prevEsnormal = prevEval.isNormal;
      this.prevShapiroWilkPValue = prevEval.pValue;
      this.prevShapiroWilkStats = prevEval.estadistico;
    },
    async copyToClipboard() {
      const data = this.exportHistogramData(this.option.series);
      try {
        await navigator.clipboard.writeText(data);
        alert("Datos copiados al portapapeles");
      } catch (err) {
        console.error("Error al copiar al portapapeles", err);
      }
    },
    async fetchData(date) {
      const dateParts = date.split("-");
      const dateFrom = new Date(
        Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2])
      );
      dateFrom.setUTCHours(7, 0, 0);
      dateFrom.setUTCDate(dateFrom.getUTCDate() - 1);
      const dateTo = new Date(
        Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2])
      );
      dateTo.setUTCHours(7, 0, 0);
      let dateFromString = dateFrom.toISOString();
      let dateToString = dateTo.toISOString();
      dateFromString = dateFromString
        .replace("T", " ")
        .replace("Z", "")
        .substring(0, dateFromString.length - 5);
      dateToString = dateToString
        .replace("T", " ")
        .replace("Z", "")
        .substring(0, dateToString.length - 5);

      let url = `https://api.cambiocuba.money/api/v1/frequencies?x_cur=${this.selectedCurrency}&date_from=${dateFromString}&date_to=${dateToString}&token=aCY78gC3kWRv1pR7VfgSlg`;
      if (this.offerType !== "General") {
        url += `&offer=${this.offerType}`;
      }
      const response = await fetch(url);
      const data = await response.json();
      return data;
    },
    generateHistogramOptions(
      xAxisBottom,
      xAxisTop,
      xAxisInterval,
      currencyName,
      data,
      meanAndMedianLines,
      barsColor,
      histogramLegend
    ) {
      return {
        backgroundColor: COLORS.BACKGROUND_COLOR,
        xAxis: {
          type: "value",
          min: xAxisBottom,
          max: xAxisTop,
          interval: xAxisInterval,
          axisLabel: {
            fontSize: AXIS_FONT_SIZE,
            color: COLORS.AXIS_LABEL_COLOR,
            fontFamily: "Nexa",
            fontWeight: 700,
          },
          name: `Tasas de cambio del ${currencyName}`,
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: AXIS_FONT_SIZE,
            color: COLORS.AXIS_NAME_COLOR,
            fontFamily: "Nexa",
            fontWeight: 700,
          },
          nameGap: 30,
          splitLine: {
            show: true,
            lineStyle: {
              color: COLORS.X_AXIS_SPLIT_LINE_COLOR,
            },
            showMaxLabel: false,
            showMaxLine: false,
          },
          axisLine: {
            lineStyle: {
              color: COLORS.X_AXIS_LINE_COLOR,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            fontSize: AXIS_FONT_SIZE,
            color: COLORS.AXIS_LABEL_COLOR,
            fontFamily: "Nexa",
            fontWeight: 700,
          },
          name: "Cantidad de ofertas",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: AXIS_FONT_SIZE,
            color: COLORS.AXIS_NAME_COLOR,
            fontFamily: "Nexa",
            fontWeight: 700,
          },
          nameGap: 40,
          splitLine: {
            lineStyle: {
              color: COLORS.Y_AXIS_SPLIT_LINE_COLOR,
            },
            showMaxLine: false,
          },
          axisLine: {
            lineStyle: {
              color: COLORS.Y_AXIS_LINE_COLOR,
            },
          },
          axisTick: {
            show: false,
          },
          endOnTick: false,
        },
        grid: {
          top: 100,
          left: "10%",
          right: "5%",
        },
        legend: {
          textStyle: {
            fontSize: 20,
            fontFamily: "Nexa",
            fontWeight: 700,
            textTransform: "uppercase",
          },
          left: "7%",
          top: "10%",
          padding: 0,
          paddingTop: 40,
          icon: "rect",
        },
        series: [
          {
            name: histogramLegend.toUpperCase(),
            data: data,
            type: "bar",
            barWidth: "100%",
            itemStyle: {
              color: barsColor,
            },
          },
          ...meanAndMedianLines,
        ],
      };
    },
    async getOptions() {
      const me = this;
      me.isLoading = true;
      me.data = await this.fetchData(this.selectedDate);
      me.prevData = await this.fetchData(this.previousWeekDate);

      const isWithinRange = (value) =>
        value >= me.minLimit && value <= me.maxLimit;
      const processSeriesData = (data) => {
        let expandedData = [];
        data.forEach((item) => {
          for (let i = 0; i < item.count; i++) {
            expandedData.push(item._id.value);
          }
        });
        return expandedData.filter((value) => isWithinRange(value));
      };

      const getLowerAndUpperBound = (mean, data, std) => {
        const distanceMeanFromMax = Math.max(...data) - mean;
        const distanceMeanFromMin = mean - Math.min(...data);

        const maxDistance = Math.max(
          distanceMeanFromMin,
          distanceMeanFromMax,
          2 * std
        );
        return [Math.floor(mean + maxDistance), Math.floor(mean - maxDistance)];
      };

      const calculateStats = (data) => {
        let mean = ss.mean(data);
        let stdDev = ss.standardDeviation(data);
        let top = Math.floor(mean + 2 * stdDev);
        let bottom = Math.floor(mean - 2 * stdDev);
        let upperBound = Math.floor(mean + 2 * stdDev);
        let lowerBound = Math.floor(mean - 2 * stdDev);

        // Remove from data values that are outside 2 standard deviations
        data = data.filter(
          (value) => value >= lowerBound && value <= upperBound
        );

        [upperBound, lowerBound] = getLowerAndUpperBound(mean, data, stdDev);

        const median = ss.median(data);
        mean = ss.mean(data);
        stdDev = ss.standardDeviation(data);
        const mode = ss.mode(data);
        const skewness = ss.sampleSkewness(data);
        const basicSkewness = (3 * (mean - median)) / stdDev;
        const kurtosis = ss.sampleKurtosis(data);

        bottom = lowerBound;
        top = upperBound;

        return {
          median,
          mean,
          mode,
          stdDev,
          skewness,
          kurtosis,
          top,
          bottom,
          upperBound,
          lowerBound,
          basicSkewness,
        };
      };

      function roundIntervalToNearestFive(interval) {
        return 5 * Math.round(interval / 5);
      }

      function calculateInterval(range) {
        const estimatedChartWidth = innerWidth * 0.8;
        let maxLabels = Math.floor(estimatedChartWidth / (AXIS_FONT_SIZE * 3)); // cada label ocupa 3 caracteres
        if (maxLabels < 1) {
          maxLabels = 1; // Evitar división por cero, aseguramos al menos 1 etiqueta
        }

        let interval = Math.trunc(range / maxLabels);
        if (interval < MINIMUM_X_AXIS_INTERVAL) {
          interval = MINIMUM_X_AXIS_INTERVAL;
        }

        return roundIntervalToNearestFive(interval);
      }

      const seriesData = processSeriesData(me.data);
      const prevSeriesData = processSeriesData(me.prevData);

      const currentStats = calculateStats(seriesData);
      const prevStats = calculateStats(prevSeriesData);

      me.mean = currentStats.mean;
      me.median = currentStats.median;
      me.mode = currentStats.mode;
      me.std = currentStats.stdDev;
      me.skewness = currentStats.skewness;
      me.basicSkewness = currentStats.basicSkewness;
      me.kurtosis = currentStats.kurtosis;
      me.bottom = currentStats.bottom;
      me.top = currentStats.top;
      me.lowerBound = currentStats.lowerBound;
      me.upperBound = currentStats.upperBound;

      me.prevMedian = prevStats.median;
      me.prevMean = prevStats.mean;
      me.prevMode = prevStats.mode;
      me.prevStd = prevStats.stdDev;
      me.prevSkewness = prevStats.skewness;
      me.prevBasicSkewness = prevStats.basicSkewness;
      me.prevKurtosis = prevStats.kurtosis;
      me.prevBottom = prevStats.bottom;
      me.prevTop = prevStats.top;
      me.prevLowerBound = prevStats.lowerBound;
      me.prevUpperBound = prevStats.upperBound;

      const expandedData = seriesData.filter(
        (value) => value >= me.lowerBound && value <= me.upperBound
      );

      me.expandendData = expandedData;
      const binWidth = me.bins;
      const binCount = Math.ceil(
        (Math.max(...expandedData) - Math.min(...expandedData)) / binWidth
      );
      let bins = Array(binCount).fill(0);
      const binEdges = [];
      for (let i = 0; i < binCount; i++) {
        binEdges.push(Math.min(...expandedData) + i * binWidth);
      }
      expandedData.forEach((value) => {
        const binIndex = Math.floor(
          (value - Math.min(...expandedData)) / binWidth
        );
        bins[binIndex]++;
      });
      bins = bins.filter((bin) => !isNaN(bin));
      let xAxisData =
        binWidth > 1 ? binEdges.map((edge) => edge + binWidth / 2) : binEdges;

      me.seriesData = bins;

      const prevExpandedData = prevSeriesData.filter(
        (value) => value >= me.prevLowerBound && value <= me.prevUpperBound
      );
      me.prevExpandendData = prevExpandedData;
      const prevBinCount = Math.ceil(
        (Math.max(...prevExpandedData) - Math.min(...prevExpandedData)) /
          binWidth
      );
      let prevBins = Array(prevBinCount).fill(0);
      const prevBinEdges = [];
      for (let i = 0; i < prevBinCount; i++) {
        prevBinEdges.push(Math.min(...prevExpandedData) + i * binWidth);
      }
      prevExpandedData.forEach((value) => {
        const binIndex = Math.floor(
          (value - Math.min(...prevExpandedData)) / binWidth
        );
        prevBins[binIndex]++;
      });
      prevBins = prevBins.filter((bin) => !isNaN(bin));

      const prevXAxisData =
        binWidth > 1
          ? prevBinEdges.map((edge) => edge + binWidth / 2)
          : prevBinEdges;
      me.prevSeriesData = prevBins;

      function createStdLines(median, mean) {
        const lines = [];

        const medianLabelAligment = median > mean ? "left" : "right";
        const meanLabelAligment = median > mean ? "right" : "left";

        // Agregar la línea de la mediana
        lines.push({
          type: "line",
          markLine: {
            silent: true,
            symbol: "none",
            label: {
              show: true,
              position: "end",
              formatter: "Mediana ({c})",
              fontSize: MEAN_MODE_LABEL_FONT_SIZE,
              fontFamily: "Nexa",
              fontWeight: 700,
              color: COLORS.MEDIAN_LINE_COLOR,
              align: medianLabelAligment,
            },
            data: [
              {
                xAxis: median,
              },
            ],
            lineStyle: {
              color: COLORS.MEDIAN_LINE_COLOR,
              width: 2,
              type: "solid",
            },
          },
        });
        // Agregar la línea de la media
        lines.push({
          type: "line",
          markLine: {
            silent: true,
            symbol: "none",
            label: {
              show: true,
              position: "end",
              formatter: "Media ({c})",
              fontSize: MEAN_MODE_LABEL_FONT_SIZE,
              fontFamily: "Nexa",
              fontWeight: 700,
              color: COLORS.MEAN_LINE_COLOR,
              align: meanLabelAligment,
            },
            data: [
              {
                xAxis: mean,
              },
            ],
            lineStyle: {
              color: COLORS.MEAN_LINE_COLOR,
              width: 2,
              type: "solid",
            },
          },
        });
        return lines;
      }

      const currentStds = createStdLines(me.median, me.mean);
      const prevStds = createStdLines(me.prevMedian, me.prevMean);
      me.option = me.generateHistogramOptions(
        me.bottom,
        me.top,
        calculateInterval(me.top - me.bottom),
        me.currencyName,
        xAxisData.map((x, i) => [x, me.seriesData[i]]),
        currentStds,
        COLORS.HISTOGRAM_BAR,
        "Histograma Actual"
      );

      me.optionOLD = me.generateHistogramOptions(
        me.prevBottom,
        me.prevTop,
        calculateInterval(me.prevTop - me.prevBottom),
        me.currencyName,
        prevXAxisData.map((x, i) => [x, me.prevSeriesData[i]]),
        prevStds,
        COLORS.HISTOGRAM_PREV_BAR,
        "Histograma Previo"
      );

      me.isLoading = false;
      setTimeout(() => {
        this.saveChartsAsImage("update");
      }, 3000);
    },
  },
};
</script>

<style scoped>
.dim-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
.chart {
  width: 100%;
  height: 400px;
}

.table-container {
  width: 100%;
  padding: 7% 7% 2% 7%;
  background-color: #c3dfff;
}

.statistics-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 0;
  /* reset table styles */
  background-color: #c3dfff;
  box-shadow: none !important; /* Quita la sombra */
  overflow: visible !important;
  font-family: "Nexa", sans-serif;
  font-weight: 700;
}

.statistics-table thead tr,
.statistics-table tbody tr:not(:last-child) {
  border-bottom: 4px solid #e13449;
}

.statistics-table tbody tr:last-child {
  border-bottom: 4px solid transparent;
}

.statistics-table td,
.statistics-table th {
  border-right: 4px solid #1149c8;
}

/* 📌 Estilos base (Mobile First - XS) */
.statistics-table tbody td:not(:first-child) {
  font-size: 14pt;
  color: #09142d;
  min-width: 35%;
}

.statistics-table tbody tr td:first-child {
  font-size: 14pt;
  color: #003abf;
  text-align: right;
  padding-right: 2%;
}

.statistics-table thead th {
  font-size: 18pt;
  color: #d8122a !important;
  padding-top: 0;
  padding-bottom: 2%;
  line-height: 20pt;
}

@media (min-width: 481px) {
  .statistics-table tbody td:not(:first-child) {
    font-size: 16pt;
    min-width: 30%;
  }

  .statistics-table tbody tr td:first-child {
    font-size: 16pt;
    padding-right: 4%;
  }

  .statistics-table thead th {
    font-size: 20pt;
    line-height: 22pt;
  }
}

@media (min-width: 769px) {
  .statistics-table tbody td:not(:first-child) {
    font-size: 12pt;
    min-width: 20%;
  }

  .statistics-table tbody tr td:first-child {
    font-size: 12pt;
    padding-right: 6%;
  }

  .statistics-table thead th {
    font-size: 17pt;
    line-height: 25pt;
  }
}

@media (min-width: 960px) {
  .statistics-table tbody td:not(:first-child) {
    font-size: 20pt;
    min-width: 26%;
  }

  .statistics-table tbody tr td:first-child {
    font-size: 19.9pt;
    padding-right: 6%;
  }

  .statistics-table thead th {
    font-size: 25pt;
    line-height: 25pt;
  }
}

.statistics-table td {
  padding-bottom: 2%;
  padding-top: 2%;
  text-align: center;
}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 1em;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.control-panel > div {
  flex: 1 0 20%; /* Esto hará que cada div ocupe al menos el 20% del ancho total, pero puede crecer si hay espacio disponible */
  margin: 0.5em;
}

input[type="number"],
input[type="date"],
select {
  display: block;
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0px;
}

input[type="checkbox"] {
  margin-right: 0.5em;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5em;
}

button {
  background-color: #007bff; /* Color de fondo */
  border: none; /* Sin borde */
  color: white; /* Color del texto */
  padding: 10px 20px; /* Relleno */
  text-align: center; /* Alineación del texto */
  text-decoration: none; /* Sin subrayado */
  display: inline-block; /* Mostrar en línea */
  font-size: 16px; /* Tamaño de la fuente */
  margin: 4px 2px; /* Margen */
  cursor: pointer; /* Cursor de puntero */
  border-radius: 4px; /* Bordes redondeados */
  transition: background-color 0.3s ease; /* Transición suave */
}

button:hover {
  background-color: #0056b3; /* Color de fondo al pasar el ratón */
}

.chart-top-space {
  margin-top: 20px;
}

.font-nexa {
  font-family: "Nexa", sans-serif;
}
</style>
